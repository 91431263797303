import React from "react";
import {useCompareContext} from "../../contexts/compare-context";
import {useQuickQuoteContext} from "../../contexts/quick-quote-context";
import Container from "../primitives/grid/container";
import Typography from "../primitives/typography";
import Divider from "../primitives/divider";
import StyledButton, {StyledButtonAsLink} from "../primitives/styled-button";
import Row from "../primitives/grid/row";
import Col from "../primitives/grid/col";
import Background from "../primitives/background";
import AspectRatio from "../primitives/aspect-ratio";
import ColorVariantCard from "./color-variant-card";
import {breakpoints} from "../primitives/tokens";
import { useOnClickOutside } from 'usehooks-ts'
import {colors} from "../primitives/colors";

const CompareView = () => {

    const { items, setItems, setIsOpen } = useCompareContext()
    const { width, height } = useQuickQuoteContext()

    const ref = React.useRef<HTMLDivElement>(null)
    useOnClickOutside(ref, () => setIsOpen(false))

    return (
        <div ref={ref}>
            <Container css={{
                position: 'sticky',
                top: '32px',
                backgroundColor: colors.shadesWhite,
                zIndex: 2,
            }}>
                <div css={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingTop: '43px',
                    paddingBottom: '12px',
                    [breakpoints.mb]: {
                        paddingTop: '37px',
                    },
                    borderBottom: `1px solid ${colors.secondaryBeige4}`
                }}>
                    <Typography fontSize={{
                        dt: 'h1',
                        tb: 'h2',
                        mb: 'h3'
                    }} color="neutral10">
                        Compare Options
                    </Typography>
                    <StyledButton className="filled-blue" onClick={() => setIsOpen(false)}>
                        Close
                        <span css={{
                            [breakpoints.mb]: {
                                display: 'none'
                            }
                        }}>
                            {" "}
                            & Back
                        </span>
                    </StyledButton>
                </div>
            </Container>
            <Container css={{
                marginBottom: '100px',
                [breakpoints.mb]: {
                    marginBottom: '64px'
                },
                position: 'relative',
            }}>
                <Row css={{
                    marginTop: '28px',
                    rowGap: '84px',
                    [breakpoints.mb]: {
                        rowGap: '64px'
                    }
                }}>
                    {items.map((variant) => (
                        <Col breakpoints={{
                            dt: { span: 4 },
                            tb: { span: 6 },
                            mb: { span: 4 }
                        }}>
                            <Background backgroundColor="shadesWhite">
                                <AspectRatio breakpoints={{
                                    dt: { width: 443, height: 300 },
                                    mb: { width: 358, height: 300 }
                                }} css={{
                                    borderRadius: '10px',
                                    [breakpoints.mb]: {
                                        borderRadius: '8px'
                                    }
                                }}>
                                    <div css={{ height: '100%' }}>
                                        <img src={`${variant.renderImages[0]}?w=600`} css={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                            borderRadius: '10px',
                                            [breakpoints.mb]: {
                                                borderRadius: '8px'
                                            }
                                        }} />
                                        <StyledButton
                                            css={{
                                                position: 'absolute',
                                                right: '20px',
                                                top: '20px',
                                                padding: 'unset',
                                                height: '29px',
                                                width: '92px',
                                                [breakpoints.mb]: {
                                                    right: '11px',
                                                    top: '10px'
                                                }
                                            }}
                                            className="fill-white"
                                            onClick={() => {
                                                setItems((e) => e.filter(x => x.handle !== variant.handle))
                                                if (items.length === 1) {
                                                    setIsOpen(false)
                                                }
                                            }}
                                        >
                                            <Typography fontSize={{
                                                dt: "c1"
                                            }} css={{ display: 'flex', gap: '6px', alignItems: 'center', justifyContent: 'center' }}>
                                                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.5 1L1.5 13M1.5 1L13.5 13" stroke="#1A1A1A" strokeLinecap="square" strokeLinejoin="round"/>
                                                </svg>
                                                <span>Remove</span>
                                            </Typography>
                                        </StyledButton>
                                    </div>
                                </AspectRatio>
                                <ColorVariantCard variant={variant} />
                                <StyledButtonAsLink to={variant.permalink} css={{
                                    width: '100%',
                                }} onClick={() => {
                                    setIsOpen(false)
                                }} className="filled-orange">
                                    Shop Now
                                </StyledButtonAsLink>
                            </Background>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )

}

export default CompareView
